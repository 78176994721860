import React from 'react';
import { Link } from 'react-router-dom';
import { Globe, Play, Share2 } from 'lucide-react';
import { DIGITAL_CATEGORIES, MOCK_DIGITAL } from '../../../data/mockDigital';

const DigitalSubcategories = () => {
  const getIcon = (iconName: string) => {
    switch (iconName) {
      case 'Globe': return Globe;
      case 'Play': return Play;
      case 'Share2': return Share2;
      default: return Globe;
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Digital Advertising</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Platforms</option>
            <option>Social Media</option>
            <option>Video</option>
            <option>Display</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {DIGITAL_CATEGORIES.map((category) => {
          const Icon = getIcon(category.icon);
          const inventory = MOCK_DIGITAL.filter(item => item.type === category.id);
          
          return (
            <Link
              key={category.id}
              to={category.id}
              className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
            >
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="flex items-center">
                    <Icon className="h-6 w-6 text-purple-600 mr-2" />
                    <h2 className="text-lg font-medium text-gray-900">
                      {category.name}
                    </h2>
                  </div>
                  <p className="mt-2 text-sm text-gray-600">
                    {category.description}
                  </p>
                </div>
                <span className="text-sm text-gray-500">
                  {inventory.length} spots
                </span>
              </div>

              {inventory.length > 0 && (
                <div className="mt-4 space-y-2">
                  {inventory.slice(0, 2).map(item => (
                    <div key={item.id} className="text-sm text-gray-600 flex justify-between items-center">
                      <span>{item.title}</span>
                      <span className="text-xs text-purple-600">
                        From ₹{item.pricing.basePrice.toLocaleString()}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default DigitalSubcategories;