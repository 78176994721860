import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Import remaining category components
import TVCategory from '../pages/Category/TV';
import RadioCategory from '../pages/Category/Radio';
import AirportCategory from '../pages/Category/Airport';
import TheatreCategory from '../pages/Category/Theatre';
import NewspaperCategory from '../pages/Category/Newspaper';
import MagazineCategory from '../pages/Category/Magazine';
import BTLCategory from '../pages/Category/BTL';
import VehicleCategory from '../pages/Category/Vehicle';
import InfluencerCategory from '../pages/Category/Influencer';
import DigitalCategory from '../pages/Category/Digital';
import OOHCategory from '../pages/Category/OOH';

export default function CategoryRoutes() {
  return (
    <Routes>
      <Route path="tv/*" element={<TVCategory />} />
      <Route path="radio/*" element={<RadioCategory />} />
      <Route path="airport/*" element={<AirportCategory />} />
      <Route path="theatre/*" element={<TheatreCategory />} />
      <Route path="newspaper/*" element={<NewspaperCategory />} />
      <Route path="magazine/*" element={<MagazineCategory />} />
      <Route path="btl/*" element={<BTLCategory />} />
      <Route path="vehicle/*" element={<VehicleCategory />} />
      <Route path="influencer/*" element={<InfluencerCategory />} />
      <Route path="digital/*" element={<DigitalCategory />} />
      <Route path="ooh/*" element={<OOHCategory />} />
    </Routes>
  );
}