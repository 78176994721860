export const INFLUENCERS = [
  {
    id: "lifestyle-1",
    name: "Komal Pandey",
    categories: ["Lifestyle", "Fashion"],
    languages: ["Hindi", "English"],
    handles: {
      instagram: "komalpandeyofficial",
      youtube: "KomalPandey"
    },
    stats: {
      instagram: {
        followers: 1800000,
        engagement: 6.5
      },
      youtube: {
        subscribers: 1200000,
        views: 150000000
      },
      engagement: 7.2
    },
    pricing: [
      { type: "Instagram Story", amount: 150000 },
      { type: "Instagram Reel", amount: 300000 },
      { type: "YouTube Video", amount: 800000 }
    ],
    coverImage: "https://images.unsplash.com/photo-1483985988355-763728e1935b",
    bio: "Fashion and lifestyle content creator"
  },
  {
    id: "tech-1",
    name: "Technical Guruji",
    categories: ["Tech", "Education"],
    languages: ["Hindi", "English"],
    handles: {
      instagram: "technicalguruji",
      youtube: "TechnicalGuruji"
    },
    stats: {
      instagram: {
        followers: 5800000,
        engagement: 4.2
      },
      youtube: {
        subscribers: 23000000,
        views: 4000000000
      },
      engagement: 5.8
    },
    pricing: [
      { type: "YouTube Integration", amount: 1500000 },
      { type: "Instagram Story", amount: 200000 }
    ],
    coverImage: "https://images.unsplash.com/photo-1531297484001-80022131f5a1",
    bio: "India's leading tech reviewer"
  }
];