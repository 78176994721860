export interface DigitalInventory {
  id: string;
  title: string;
  type: string;
  specifications: {
    format: string;
    duration?: string;
    targeting: string;
    platform: string;
  };
  pricing: {
    basePrice: number;
    unit: string;
    minimumBudget: number;
  };
  features: string[];
  metrics: {
    avgViews?: string;
    engagementRate?: string;
    completion?: string;
  };
  availability: {
    status: 'available' | 'booked';
  };
}

export const MOCK_DIGITAL: DigitalInventory[] = [
  {
    id: 'yt-premium',
    title: 'YouTube Premium Pre-Roll Ads',
    type: 'video',
    specifications: {
      format: 'Video Ad',
      duration: '15-30 seconds',
      targeting: 'Custom Audience',
      platform: 'YouTube'
    },
    pricing: {
      basePrice: 100000,
      unit: 'per_campaign',
      minimumBudget: 100000
    },
    features: [
      'Skip Option After 5s',
      'Custom Audience Targeting',
      'Performance Analytics'
    ],
    metrics: {
      avgViews: '500,000+',
      engagementRate: '15%',
      completion: '70%'
    },
    availability: {
      status: 'available'
    }
  }
];

export const DIGITAL_CATEGORIES = [
  {
    id: 'programmatic',
    name: 'Programmatic Ads',
    description: 'Automated digital ad buying',
    icon: 'Globe'
  },
  {
    id: 'social',
    name: 'Social Media',
    description: 'Ads on major social platforms', 
    icon: 'Share2'
  },
  {
    id: 'video',
    name: 'Video Ads',
    description: 'YouTube and OTT platforms',
    icon: 'Play'
  }
];