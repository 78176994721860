import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Tv, 
  Radio, 
  Newspaper, 
  Book, 
  ShoppingBag, 
  Car,
  Users,
  Globe,
  MapPin,
  Plane,
  Film,
  MonitorPlay,
  Bus
} from 'lucide-react';

const categories = [
  { 
    name: 'Television',
    icon: Tv,
    path: '/category/tv',
    description: 'National and regional TV channels'
  },
  { 
    name: 'Radio',
    icon: Radio,
    path: '/category/radio',
    description: 'FM and community radio stations'
  },
  {
    name: 'Newspaper',
    icon: Newspaper,
    path: '/category/newspaper',
    description: 'Print media advertising'
  },
  {
    name: 'Magazine',
    icon: Book,
    path: '/category/magazine',
    description: 'Magazine advertising solutions'
  },
  {
    name: 'BTL',
    icon: ShoppingBag,
    path: '/category/btl',
    description: 'Below-the-line activations'
  },
  {
    name: 'Vehicle',
    icon: Car,
    path: '/category/vehicle',
    description: 'Vehicle and fleet branding'
  },
  {
    name: 'Influencer',
    icon: Users,
    path: '/category/influencer',
    description: 'Social media influencer marketing'
  },
  {
    name: 'Digital',
    icon: Globe,
    path: '/category/digital',
    description: 'Digital and programmatic advertising'
  },
  {
    name: 'OOH',
    icon: MapPin,
    path: '/category/ooh',
    description: 'Traditional outdoor advertising'
  },
  {
    name: 'Airport',
    icon: Plane,
    path: '/category/airport',
    description: 'Airport advertising opportunities'
  },
  {
    name: 'Theatre',
    icon: Film,
    path: '/category/theatre',
    description: 'Cinema and theatre advertising'
  },
  {
    name: 'DOOH',
    icon: MonitorPlay,
    path: '/category/dooh',
    description: 'Digital out-of-home advertising'
  },
  {
    name: 'Transit',
    icon: Bus,
    path: '/category/transit',
    description: 'Transit media advertising'
  }
];

export default function CategoryGrid() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {categories.map((category) => (
        <Link
          key={category.name}
          to={category.path}
          className="group bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
        >
          <div className="flex items-center mb-4">
            <category.icon className="h-6 w-6 text-purple-600" />
            <h3 className="ml-2 text-lg font-medium text-gray-900 group-hover:text-purple-600">
              {category.name}
            </h3>
          </div>
          <p className="text-sm text-gray-600">
            {category.description}
          </p>
        </Link>
      ))}
    </div>
  );
}