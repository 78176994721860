export const MOCK_OOH = [
  {
    id: 'bill-1',
    title: 'Premium LED Billboard - Marine Drive',
    type: 'billboard',
    location: {
      city: 'Mumbai',
      area: 'Marine Drive',
      coordinates: {
        lat: 18.9438,
        lng: 72.8231
      }
    },
    specifications: {
      size: '40ft x 20ft',
      type: 'Digital LED',
      visibility: '500m',
      lighting: '24/7 LED'
    },
    pricing: {
      basePrice: 250000,
      unit: 'per_month',
      minimumDuration: 1
    },
    features: [
      'Prime Location',
      'High Traffic',
      'Digital Display',
      '24/7 Visibility'
    ],
    metrics: {
      dailyTraffic: '100,000+',
      visibility: 'Excellent',
      targetAudience: 'Premium'
    },
    images: [
      'https://images.unsplash.com/photo-1557804506-669a67965ba0',
      'https://images.unsplash.com/photo-1557804506-669a67965ba1'
    ]
  },
  {
    id: 'metro-1',
    title: 'Metro Station Branding - MG Road',
    type: 'transit',
    location: {
      city: 'Bangalore',
      area: 'MG Road Metro',
      coordinates: {
        lat: 12.9758,
        lng: 77.6096
      }
    },
    specifications: {
      size: 'Multiple Formats',
      type: 'Station Branding',
      visibility: 'Station Wide',
      footfall: '50,000+ daily'
    },
    pricing: {
      basePrice: 150000,
      unit: 'per_month',
      minimumDuration: 3
    },
    features: [
      'High Footfall',
      'Multiple Formats',
      'Premium Audience'
    ],
    metrics: {
      dailyFootfall: '50,000+',
      dwell: '15-20 mins',
      audience: 'Urban Professionals'
    },
    images: [
      'https://images.unsplash.com/photo-1515263487990-61b07816b324',
      'https://images.unsplash.com/photo-1515263487990-61b07816b325'
    ]
  }
];